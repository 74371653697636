<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="usersList"
        :search="search"
        :loading="loading"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="usersMeta.total_count"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <v-toolbar-title>Користувачі</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="shrink mr-10"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
              clearable
            ></v-text-field>
            <v-dialog v-model="dialog" max-width="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on"> Додати Користувача </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.first_name"
                          label="Ім'я"
                          @input="$v.editedItem.first_name.$touch()"
                          @blur="$v.editedItem.first_name.$touch()"
                          :error-messages="firstNameErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.last_name"
                          label="Прізвище"
                          @input="$v.editedItem.last_name.$touch()"
                          @blur="$v.editedItem.last_name.$touch()"
                          :error-messages="lastNameErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <PhoneEdit
                          :value="editedItem.phone"
                          :is-touched="isPhoneTouched"
                          label="Телефон"
                          validate
                          :disabled="editedIndex > -1"
                          @change="(val) => (editedItem.phone = val)"
                          @validation="(val) => (isPhoneValid = val)"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="save">Так</v-btn>
                  <v-btn text @click="close">Нi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">Користувача буде видалено</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="deleteItemConfirm">Так</v-btn>
                  <v-btn text @click="closeDelete">Нi</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogUpdateStatus" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">Статус буде оновлено</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="updateItemStatusConfirm">Так</v-btn>
                  <v-btn text @click="closeUpdateStatusDialog">Нi</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.id="{ item }">
          <router-link
            :to="{
              name: 'UserCard',
              params: { id: currentCompanyId, userId: item.id },
            }"
            class="text-decoration-none"
          >
            <v-tooltip :open-on-focus="false" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-if="item.status !== null && item.status !== 1"
                  :style="{ color: getListStatusColor(item.status) }"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.id }}
                </span>
                <span v-else v-bind="attrs" v-on="on">{{ item.id }}</span>
              </template>
              <span>{{ getListStatusTitle(item.status) }}</span>
            </v-tooltip>
          </router-link>
        </template>
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{
              name: 'UserCard',
              params: { id: currentCompanyId, userId: item.id },
            }"
            class="text-decoration-none"
          >
            {{ item.first_name }} {{ item.last_name }}
          </router-link>
        </template>
        <template v-slot:item.phone="{ item }">{{ formatPhoneNumber(item.phone) }}</template>
        <template v-slot:item.status="{ item }">{{ item.status | getStatusTitle }}</template>
        <template v-slot:item.created_at="{ item }">{{ item.created_at | getShortDate }}</template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.status === 1"
                small
                class="mr-2"
                @click="updateStatusItemDialogOpen(item, 2)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-lock-outline
              </v-icon>
              <v-icon
                v-else-if="item.status === 2"
                @click="updateStatusItemDialogOpen(item, 1)"
                :style="{ color: getListStatusColor(item.status) }"
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
              >
                mdi-lock-open-outline
              </v-icon>
            </template>
            <span v-if="item.status === 1">Заблокувати</span>
            <span v-else-if="item.status === 2">Розблокувати</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" small v-bind="attrs" @click="editItem(item)" v-on="on"> mdi-pencil-outline</v-icon>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-tooltip :open-on-focus="false" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" small v-bind="attrs" @click="deleteItem(item)" v-on="on"> mdi-delete-outline</v-icon>
            </template>
            <span>Видалити</span>
          </v-tooltip>
          <v-tooltip :open-on-focus="false" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" @click="navigateToUserCard(item)" v-on="on"> mdi-eye-outline</v-icon>
            </template>
            <span>Переглянути</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData">Оновити</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { USER_SCHEMA } from '@/const/apiSchemas'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import convertDate from '@/mixins/convertDate'
import convertUserStatusesTypes from '@/mixins/convertStatusesTypes'
import resetTableData from '@/mixins/resetTableData'
import BackButton from '@/components/common/BackButton'
import AwaitingSearch from '@/mixins/constructor/awaitingSearch'
import PhoneEdit from '@/components/common/PhoneEdit'
import { formatPhoneNumber } from '@/helpers/formatPhoneNumber'
import CloneDeep from 'lodash/cloneDeep'
import sortUtils from '@/mixins/sortUtils'

export default {
  name: 'UsersList',
  components: { PhoneEdit, BackButton },

  mixins: [validationMixin, convertDate, convertUserStatusesTypes, resetTableData, AwaitingSearch, sortUtils],

  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogUpdateStatus: false,
    search: '',
    loading: false,
    clickedUserId: null,
    selectedTerminalId: null,
    newStatus: null,
    headers: [
      { text: 'ID', align: 'start', sortable: true, value: 'id' },
      { text: 'ПIБ', value: 'name', sortable: false },
      { text: 'Телефон', value: 'phone', sortable: true },
      { text: 'Статус', value: 'status', sortable: true, align: 'center' },
      { text: 'Дата реєстрації', value: 'created_at', sortable: true, align: 'center' },
      { text: '', value: 'actions', sortable: false, width: '125px', align: 'right' },
    ],
    usersList: [],
    editedIndex: -1,
    sortBy: '',
    sortDesc: false,
    options: {},
    isPhoneValid: false,
    isPhoneTouched: false,
    editedItem: {},
    defaultItem: {},
  }),

  validations() {
    return {
      editedItem: {
        first_name: { required },
        last_name: { required },
      },
      selectedTerminalId: { required },
    }
  },

  computed: {
    ...mapState('users', ['users', 'usersMeta']),

    formTitle() {
      return this.editedIndex === -1 ? 'Створити користувача' : 'Редагувати користувача'
    },
    currentCompanyId() {
      return this.$route.params.id
    },
    firstNameErrors() {
      const errors = []
      if (!this.$v.editedItem.first_name.$dirty) return errors
      !this.$v.editedItem.first_name.required && errors.push('Це поле обов"язкове')
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.editedItem.last_name.$dirty) return errors
      !this.$v.editedItem.last_name.required && errors.push('Це поле обов"язкове')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
  },

  created() {},

  methods: {
    formatPhoneNumber,
    ...mapActions('users', ['loadUsers']),
    ...mapActions('admin', ['createNewUser', 'updateSelectedUser', 'deleteSelectedUser', 'setUserStatus']),
    ...mapActions('logs', ['displayWarningAlert']),

    initialize() {
      this.editedItem = CloneDeep(USER_SCHEMA)
      this.defaultItem = CloneDeep(USER_SCHEMA)
      this.usersList = [...this.users]
    },

    async paginateTo() {
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        const params = {
          companyId: this.currentCompanyId,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sort: this.sortObject,
        }
        if (this.search) {
          await this.loadUsers({ ...params, search: this.search })
        } else {
          await this.loadUsers(params)
        }
        this.initialize()
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },

    editItem(item) {
      this.editedIndex = this.usersList.indexOf(item)
      this.editedItem = { ...item }
      this.clickedUserId = item.id
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.usersList.indexOf(item)
      this.clickedUserId = item.id
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.loading = true
      const payload = {
        userId: this.clickedUserId,
      }
      await this.deleteSelectedUser(payload)
      this.closeDelete()
      await this.paginateTo()
      this.loading = false
    },

    close() {
      this.dialog = false
      this.$v.editedItem.$reset()
      this.isPhoneTouched = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    updateStatusItemDialogOpen(item, newStatus) {
      this.clickedUserId = item.id
      this.newStatus = newStatus
      this.dialogUpdateStatus = true
    },

    async updateItemStatusConfirm() {
      this.loading = true
      const payload = {
        userId: this.clickedUserId,
        status: this.newStatus,
      }
      await this.setUserStatus(payload)
      this.closeUpdateStatusDialog()
      await this.paginateTo()
      this.loading = false
    },

    closeUpdateStatusDialog() {
      this.dialogUpdateStatus = false
    },

    async save() {
      this.$v.editedItem.$touch()
      this.isPhoneTouched = true
      if (this.$v.$anyError || !this.isPhoneValid) return
      this.loading = true
      if (this.editedIndex > -1) {
        const payload = {
          userId: this.clickedUserId,
          updatedUser: this.editedItem,
        }
        await this.updateSelectedUser(payload)
      } else {
        const payload = {
          newUser: this.editedItem,
        }
        await this.createNewUser(payload)
      }
      this.close()
      await this.paginateTo()
      this.loading = false
    },

    navigateToUserCard(item) {
      const clickedUserId = item.id
      this.$router.push({
        name: 'UserCard',
        params: { id: this.currentCompanyId.toString(), userId: clickedUserId },
      })
    },
  },
}
</script>

<style scoped></style>
